<template>
  <div class="mx-2 mb-2">
    <div class="row">
      <div class="col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Basic Leads Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="basicinfo">
              <b-tooltip target="basicinfo" triggers="hover" placement="righttop">
                <div>Info</div>
                <p>Leads client information, all fields are required.</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Company</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-company"
                  type="text"
                  v-model="currentDataObj.details.BusinessName"
                  @keyup="
                    updateField(
                      'BusinessName', //PropertyName
                      currentDataObj.details.BusinessName, //Property Value
                      'Client Name', //Display Name,
                      currentDataObj.details.BusinessName, //Display Value
                      'name' //Data object Property Name
                    )
                  "
                />
              </div>
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Address</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-address"
                  type="text"
                  v-model="currentDataObj.details.PrimaryAddress"
                  @keyup="
                    updateField(
                      'PrimaryAddress', //PropertyName
                      currentDataObj.details.PrimaryAddress, //Property Value
                      'Address' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-lg-4 col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">City</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-city"
                  type="text"
                  v-model="currentDataObj.details.City"
                  @keyup="
                    updateField(
                      'City', //PropertyName
                      currentDataObj.details.City, //Property Value
                      'City' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-lg-4 col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">State/Province</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-state-province"
                  type="text"
                  v-model="currentDataObj.details.ProvinceState"
                  @keyup="
                    updateField(
                      'ProvinceState', //PropertyName
                      currentDataObj.details.ProvinceState, //Property Value
                      'State/Province' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-lg-4 col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Zip/Postal</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-zip-postal"
                  type="text"
                  v-model="currentDataObj.details.PostalZip"
                  @keyup="
                    updateField(
                      'PostalZip', //PropertyName
                      currentDataObj.details.PostalZip, //Property Value
                      'Zip/Postal Code' //Display Name
                    )
                  "
                />
              </div>

              <div class="col-md-4 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Email</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-email"
                  type="text"
                  v-model="currentDataObj.details.Email"
                  @keyup="
                    updateField(
                      'Email', //PropertyName
                      currentDataObj.details.Email, //Property Value
                      'Email' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-4 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Phone</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-phone"
                  type="text"
                  v-model="currentDataObj.details.Phone"
                  @input="formatPhoneNumber('Phone')"
                  @keyup="
                    updateField(
                      'Phone', //PropertyName
                      currentDataObj.details.Phone, //Property Value
                      'Phone' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-4 mb-2">
                <!-- <global-settings-modal id="slidepanel-edit-client-global-setting-type" label="Type"
                  :actionId="MODULE.CLIENTS.UX.ClientType" /> -->
                <span class="c-toolbar__state-title u-text-capitalize">Type</span>
                <v-select
                  :options="UXData.uxClientType"
                  id="slidepanel-edit-client-type"
                  :value="getSelectedDropDownValue('uxClientType', 'IntClientTypeID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Client Type', //Display Name
                      'uxClientType', //Dropdown List Object
                      'IntClientTypeID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>

              <div class="col-lg-4 col-md-12 mb-2">
                <!-- add the global setting modal to edit -->
                <global-settings-modal id="slidepanel-edit-client-global-setting-status" label="Status" :actionId="MODULE.LEADS.UX.LeadStatus" />
                <v-select
                  :options="UXData.uxLeadStatus"
                  id="slidepanel-edit-client-industry"
                  :value="getSelectedDropDownValue('uxLeadStatus', 'IntLeadStatusID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Status', //Display Name
                      'uxLeadStatus', //Dropdown List Object
                      'IntLeadStatusID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>

              <div class="col-lg-4 col-md-12 mb-2">
                <!-- add the global setting modal to edit -->
                <global-settings-modal id="slidepanel-edit-client-global-setting-stages" label="Stages" :actionId="MODULE.LEADS.UX.LeadStages" />
                <v-select
                  :options="UXData.uxLeadStages"
                  id="slidepanel-edit-client-industry"
                  :value="getSelectedDropDownValue('uxLeadStages', 'IntLifeCycleStageID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Stage', //Display Name
                      'uxLeadStages', //Dropdown List Object
                      'IntLifeCycleStageID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>

              <div class="col-lg-4 col-md-12 mb-2">
                <!-- <span class="c-toolbar__state-title u-text-capitalize">Industry</span> -->
                <global-settings-modal id="slidepanel-edit-client-global-setting-industry" label="Industry" :actionId="MODULE.CLIENTS.UX.Industry" />
                <v-select
                  :options="UXData.uxIndustry"
                  id="slidepanel-edit-client-industry"
                  :value="getSelectedDropDownValue('uxIndustry', 'IntIndustryID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Industry', //Display Name
                      'uxIndustry', //Dropdown List Object
                      'IntIndustryID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div class="col-lg-6 col-md-12 pl-lg-1">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Primary Contact Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="pcontact">
              <b-tooltip target="pcontact" triggers="hover" placement="righttop">
                <div>Info</div>
                <p>Who is the main contact for this company?</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-lg-6 col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">First Name</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-first-name"
                  type="text"
                  v-model="currentDataObj.details.PrimaryContactFirstName"
                  @keyup="
                    updateField(
                      'PrimaryContactFirstName', //PropertyName
                      currentDataObj.details.PrimaryContactFirstName, //Property Value
                      'First Name' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-lg-6 col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Last Name</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-last-name"
                  type="text"
                  v-model="currentDataObj.details.PrimaryContactLastName"
                  @keyup="
                    updateField(
                      'PrimaryContactLastName', //PropertyName
                      currentDataObj.details.PrimaryContactLastName, //Property Value
                      'Last Name' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <!-- <span class="c-toolbar__state-title u-text-capitalize">Position</span> -->
                <global-settings-modal id="slidepanel-edit-client-global-setting-position" label="Position" :actionId="MODULE.ROLES.ActionId" />
                <v-select
                  :options="UXData.uxRoles"
                  id="slidepanel-edit-client-position"
                  :value="getSelectedDropDownValue('uxRoles', 'PrimaryContactIntRoleID')"
                  @input="
                    onDropdownSelectionChange(
                      $event,
                      'Position', //Display Name
                      'uxRoles', //Dropdown List Object
                      'PrimaryContactIntRoleID' //Edit Details Property Name
                    )
                  "
                  label="displayName"
                  :clearable="false"
                ></v-select>
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Contact Email</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-contact-email"
                  type="text"
                  v-model="currentDataObj.details.PrimaryContactEmail"
                  @keyup="
                    updateField(
                      'PrimaryContactEmail', //PropertyName
                      currentDataObj.details.PrimaryContactEmail, //Property Value
                      'Contact Email' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Mobile Phone</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-mobile-phone"
                  type="text"
                  @input="formatPhoneNumber('Mobile')"
                  v-model="currentDataObj.details.Mobile"
                  @keyup="
                    updateField(
                      'Mobile', //PropertyName
                      currentDataObj.details.Mobile, //Property Value
                      'Mobile Phone' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Direct Phone</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-direct-phone"
                  type="text"
                  v-model="currentDataObj.details.PrimaryContactPhone"
                  @input="formatPhoneNumber('PrimaryContactPhone')"
                  @keyup="
                    updateField(
                      'PrimaryContactPhone', //PropertyName
                      currentDataObj.details.PrimaryContactPhone, //Property Value
                      'Contact Direct Phone' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Other Phone</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-other-phone"
                  type="text"
                  @input="formatPhoneNumber('OtherPhone')"
                  v-model="currentDataObj.details.OtherPhone"
                  @keyup="
                    updateField(
                      'OtherPhone', //PropertyName
                      currentDataObj.details.OtherPhone, //Property Value
                      'Other Phone' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Website</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-lead-website"
                  type="text"
                  v-model="currentDataObj.details.Website"
                  @keyup="
                    updateField(
                      'Website', //PropertyName
                      currentDataObj.details.Website, //Property Value
                      'Website' //Display Name
                    )
                  "
                />
              </div>
              <div class="col-md-12 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Auto cc contact emails</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-client-auot-cc-contact-emails"
                  type="text"
                  v-model="currentDataObj.details.AutoCCEmail"
                  @keyup="
                    updateField(
                      'AutoCCEmail', //PropertyName
                      currentDataObj.details.AutoCCEmail, //Property Value
                      'Auto Cc Contact Emails' //Display Name
                    )
                  "
                />
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import editMixin from "@/mixin/edit-mixin";

  import UtilityString from "@/utilities/strings";
  const utility = new UtilityString();

  export default {
    mixins: [editMixin],
    methods: {
      formatPhoneNumber(propertyName) {
        this.currentDataObj.details[propertyName] = utility.formatPhoneNumber(this.currentDataObj.details[propertyName]);
      },
    },
  };
</script>
