<template>
  <div>
    <!-- Old Iframe Implementation -->
    <div v-if="this.sidePanelDataObj.isSidePanelFrame">
      <sidePanelIFrame v-if="this.sidePanelDataObj.url" :url="this.sidePanelDataObj.url"
        :side-panel-data-obj="sidePanelDataObj" :is-iframe="this.sidePanelDataObj.isSidePanelFrame"></sidePanelIFrame>
    </div>

    <!-- New Component -->
    <div v-else>
      <!-- Header Section -->
      <panelheader v-if="sidePanelDataObj.headerDataObj" :header-data-obj="sidePanelDataObj.headerDataObj">
      </panelheader>

      <info v-if="sidePanelDataObj.info" :data-obj="sidePanelDataObj" class="px-2"></info>

      <!-- Client Details -->
      <leadsDetails v-if="sidePanelDataObj.data" :data-obj="sidePanelDataObj"></leadsDetails>

      <!-- Tabs Section -->
      <tabs v-if="sidePanelDataObj.tabSectionDataObj" :data-obj="sidePanelDataObj"
        :tab-data-obj="sidePanelDataObj.tabSectionDataObj" :iFrame="sidePanelDataObj.iFrame"></tabs>
    </div>
  </div>
</template>

<script>
//Standard Components
import EVENTS from "@/constants/events";

//Project Earth
import sidePanelIFrame from "@/components/_universal/sidepanel/sidepanel-iframe";

//Template Layout Components
import panelheader from "@/components/_universal/sidepanel/sidepanel-header";
import actionbar from "@/components/_universal/sidepanel/sidepanel-action-bar";
import panelsummary from "@/components/_universal/sidepanel/sidepanel-summary-info";
import tabs from "@/components/_universal/sidepanel/sidepanel-tabs";

import info from "@/components/_universal/details-info.vue";

import leadsDetails from "@/components/clients/leads-details.vue";

//Controller
import ClientController from "@/controllers/clients-controller";

//Initialize Controller and pass data to be loaded/displayed
const clientController = new ClientController();

export default {
  data() {
    return {
      sidePanelDataObj: {},
      EVENT_ACTION: {},
      iframeUrl: null,
    };
  },
  methods: {
    async loadData() {
      let sidePanel = this.$store.getters.getSidePanel;
      await clientController.initializedMode("leads");
      //Ensure the side bar is open
      if (sidePanel.isOpen === true) {
        //Load data from store, for side panel
        //Set Header Properties to be passed in as prop values
        this.sidePanelDataObj = await clientController.main(sidePanel.data, 'LeadsDetails');

        //Check if Side Panel should load Iframe
        if (this.sidePanelDataObj.isSidePanelFrame) {
          this.iframeUrl = this.sidePanelDataObj.url;
        }
      }
    },
  },
  components: {
    panelheader,
    leadsDetails,
    info,
    tabs,
    sidePanelIFrame,
  },
  async mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window["analytics"].page(this.EVENT_ACTION.CLIENTS.View);
    // }
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.loadData();
  },
  beforeDestroy() { },
};
</script>
